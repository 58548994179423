.custom-parallax .backgrounds {
  height: 100vh;

  z-index: -1;
  position: sticky;
  top: 0;
  left: 0;
}

.orange-color-label-blog {
  font-size: 1rem;
  color: #f57f20;
}
.custom-parallax .backgrounds.backgrounds-single {
  margin-top: calc(-1.5vw + 1px);
}
.custom-parallax .v-height {
  height: 80vh !important;
}

.custom-parallax .start-with {
  margin-top: -100vh;
}

.banner .banner-title {
  font-size: calc(2.5rem + 0.5vw);
  color: white;
}

/* Animations  */
.start-fading {
  opacity: 0;
}

.fading-in {
  animation: fadingIn 1s ease-out 0.35s forwards;
}
@keyframes fadingIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fading-out {
  animation: fadingOut 1s ease-in forwards;
}
@keyframes fadingOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Slide inside  */
.slide-in-left {
  animation: slideLeft 1s ease-in;
}
.slide-in-right {
  animation: slideRight 1s ease-in;
}
.slide-in-top {
  animation: slideTop 1s ease-in;
}
.slide-in-bottom {
  animation: slideBottom 1s ease-in;
}
@keyframes slideLeft {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes slideRight {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes slideTop {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
}
@keyframes slideBottom {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translateY(0px);
  }
}

/* Slide outside  */
.slide-out-left {
  animation: slideOutLeft 1s ease-out;
}
.slide-out-right {
  animation: slideOutRight 1s ease-out;
}
.slide-out-top {
  animation: slideOutTop 1s ease-out;
}
.slide-out-bottom {
  animation: slideOutBottom 1s ease-out;
}
@keyframes slideOutLeft {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(-100px);
  }
}
@keyframes slideOutRight {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(100px);
  }
}
@keyframes slideOutTop {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
}
@keyframes slideOutBottom {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(100px);
  }
}

.blog-viewall-btn {
  background-color: white;
}
.blog-viewall-btn:hover {
  background-color: #f08d3be8;
}

.content-fade-in {
  animation: fadingIn 1s ease-out 0.75s forwards;
}
.knowmore-btn {
  border-radius: 8px;
  background-color: white;
}
.knowmore-btn:hover {
  background-color: #f08d3be8;
}
.blog-view-btn {
  border-radius: 8px;
  background-color: white;
}
.blog-view-btn:hover {
  background-color: #f08d3be8;
}

@media (min-width: 4480px) and (max-width: 5000px) {
  .banner .banner-title {
    font-size: calc(8.5rem + 0.5vw);
    color: white;
  }
  .text-heading-orange {
    font-size: 3.5rem !important;
  }
  #section-id {
    font-size: 5.5rem;
  }

  .knowmore-btn {
    margin-top: 30px;
    border-radius: 20px;
  }
  .blogs-title {
    font-size: 6rem !important;
  }
  .blog-view-btn {
    font-size: 4rem;
    border-radius: 20px;
    height: 150px;
  }

  .blog-img-card {
    width: 100%;
    height: 700px;
  }
  .blog-card-heading {
    font-size: 3.5rem;
  }
  .blog-viewall-btn {
    font-size: 3rem;
    height: 130px;
    margin-bottom: 20px;
  }
  .arrow-icon-blog {
    height: 50px;
    width: 50px;
  }
  .blog-text-span {
    font-size: 2.5rem;
  }
  .para-ourstory {
    font-size: 3rem;
  }
  .client-higlights-heading {
    font-size: 6rem;
    margin-bottom: 90px;
  }
}

@media (min-width: 1900px) and (max-width: 2000px) {
  .custom-parallax .v-height {
    height: 80vh !important;
  }
}

@media (min-width: 1600px) and (max-width: 1700px) {
  .custom-parallax .v-height {
    height: 80vh !important;
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  .custom-parallax .v-height {
    height: 80vh !important;
  }
  /* .custom-parallax .backgrounds.backgrounds-single {
    margin-top: calc(0vw + 0px);
  } */
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .custom-parallax .v-height {
    height: 80vh !important;
  }
}

/* @media screen and (max-width: 480px) {
  
} */

/* Parallax Slider  */
.parallax-slider
  .swiper-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.parallax-slider
  .swiper-pagination-vertical.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 8px) 0;
  background: rgba(244, 126, 33, 1);
  width: var(
    --swiper-pagination-bullet-width,
    var(--swiper-pagination-bullet-size, 10px)
  );
  height: var(
    --swiper-pagination-bullet-height,
    var(--swiper-pagination-bullet-size, 10px)
  );
}
.parallax-slider .swiper-vertical > .swiper-pagination-bullets,
.parallax-slider .swiper-pagination-vertical.swiper-pagination-bullets {
  right: var(--swiper-pagination-right, 60px);
}
