.contact-form {
  background-color: #f5f7fa;
}
.contact-form-banner {
  background-image: url(../images/ContactUs.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 511px;
  width: 100%;
}
.contact-links-banner {
  background-image: url(../images/link-banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 427px;
  width: 500px;
}

.form-control:focus {
  border-color: #f47e21; /* Change to Bootstrap's primary color */
  box-shadow: none; /* Optionally remove the focus shadow */
}
.blur-img-contact-form {
  background-image: url(../images/blurbg.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.phone-number-arrows /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.send-message-button {
  border-radius: 8px;
}
/* Firefox */
/* .phone-number-arrows [type="number"] {
  -moz-appearance: textfield;
} */
@media (min-width: 4080px) and (max-width: 5000px) {
  .contact-form-web {
    height: 1831px;
    padding-top: 100px;
  }
  .contact-form-banner {
    height: 1675px;
  }
  .contact-links-banner {
    background-image: url(../images/link-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 1412px;
    width: 1588px;
  }
  .contact-form-banner h1 {
    font-size: 120px;
  }
  .get-in-touch-space {
    padding: 80px;
  }

  .contact-form-banner .get-touch-para {
    font-size: 54px;
    margin-top: 40px;
  }
  #contactForm input {
    height: 140px;
    font-size: 54px;
    margin-top: 40px;
  }
  #contactForm textarea {
    height: 140px;
    font-size: 54px;
    /* margin-bottom: 50px; */
  }
  .send-message-button {
    height: 120px;
    width: 600px;
    font-size: 44px;
  }

  .blur-img-contact-form h2 {
    font-size: 94px;
    margin-top: 60px;
  }
  .blur-img-contact-form p {
    font-size: 56px;
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .contact-icon {
    height: 50px;
    width: 50px;
    margin-top: 20px;
    margin-bottom: 15px;
    /* margin-bottom: 50px; */
  }
  .blur-img-contact-form a {
    font-size: 56px;
    margin-top: 60px;
  }
  .error {
    font-size: 3rem;
  }
  .contact-input-form {
    height: 250px;
  }
  .send-message-button {
    border-radius: 20px;
    font-size: 3rem;
  }
}
@media (min-width: 1900px) and (max-width: 2000px) {
  .contact-form-banner {
    margin-top: 80px;
    margin-bottom: 138px;
  }
}

@media (min-width: 1600px) and (max-width: 1700px) {
  .contact-form-banner {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1190px) and (max-width: 1290px) {
  .contact-links-banner {
    background-image: url(../images/link-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 427px;
    width: 422px;
  }
}

@media (min-width: 1290px) and (max-width: 1024px) {
  .contact-links-banner {
    background-image: url(../images/link-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 427px;
    width: 397px;
  }
}

@media (min-width: 1024px) and (max-width: 1190px) {
  .contact-links-banner {
    background-image: url(../images/link-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 427px;
    width: 636px;
  }
  .contact-mobile-form {
    background-image: url(../images/contact-banner-mobile.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1000px) {
  .contact-links-banner {
    background-image: url(../images/link-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 382px;
    width: 623px;
  }
  .contact-mobile-form {
    background-image: url(../images/contact-banner-mobile.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
  }
}

@media (min-width: 800px) and (max-width: 992px) {
  .contact-links-banner {
    background-image: url(../images/link-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 383px;
    width: 569px;
  }

  .contact-mobile-form {
    background-image: url(../images/contact-banner-mobile.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .contact-links-banner {
    background-image: url(../images/link-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 383px;
    width: 569px;
  }

  .contact-mobile-form {
    background-image: url(../images/contact-banner-mobile.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .contact-mobile-form {
    background-image: url(../images/contact-banner-mobile.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
  }
  .contact-links-banner {
    background-image: url(../images/link-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 426px;
    width: 339px;
  }

  .blur-img-contact-form {
    background-image: url(../images/blurbg.png);
    background-size: cover;
    background-repeat: no-repeat;
  }

  .contact-form {
    background-color: white;
  }
}

@media screen and (max-width: 280px) {
  .contact-mobile-form p {
    font-size: 10px;
  }
  .contact-mobile-form h2 {
    font-size: 14px;
  }
  .contact-links-banner {
    background-image: url(../images/link-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 337px;
    width: 249px;
  }
  .contact-mobile-form a {
    font-size: 11px;
  }
  .contact-mobile-form .icon {
    size: 10px;
  }
}
