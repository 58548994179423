.flip-container-ob {
  perspective: 1000px;
}

.flip-container-ob:hover .flipper-ob,
.flip-container-ob:hover .flipper-ob {
  transform: rotateY(180deg);
}

.flipper-ob {
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 450px;
  max-width: 100%; /* Adjust this value as needed */
  /* height: auto; */
}

.front-ob,
.back-ob {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.front-ob img,
.back-ob img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Maintain aspect ratio */
}

.front-ob {
  z-index: 2;
}

.back-ob {
  transform: rotateY(180deg);
}

.contact-ob-img {
  position: relative;
  width: 100%;
  height: auto;
}
.contact-button-ob {
  position: absolute;
  top: 56%;
  left: 22%;
  border-radius: 8px;
}

.visit-website-img {
  position: relative;
  height: auto;
  width: 100%;
}
.visit-button-ob {
  position: absolute;
  top: 60%;
  left: 20%;
  border-radius: 8px;
}

.contact-button-ob:hover {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.434);
}
.visit-button-ob:hover {
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.434);
}

@media (min-width: 4480px) and (max-width: 5000px) {
  .contact-button-ob {
    position: absolute;
    top: 65%;
    left: 23%;
    height: 150px;
    width: 700px;
    font-size: 3.5rem;
    border-radius: 20px;
  }
  .visit-button-ob {
    position: absolute;
    top: 70%;
    left: 23%;
    height: 150px;
    width: 700px;
    font-size: 3.5rem;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flipper-ob {
    height: 1541px;
    max-width: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: 1s;
    width: 100%;
  }
  .our-brands-title {
    font-size: 6rem !important;
    margin-bottom: 50px;
  }
}

@media (min-width: 1900px) and (max-width: 2000px) {
  .contact-button-ob {
    position: absolute;
    top: 67%;
    left: 27%;
  }
  .visit-button-ob {
    position: absolute;
    top: 71%;
    left: 26%;
  }
}
@media (min-width: 1700px) and (max-width: 1900px) {
  .contact-button-ob {
    position: absolute;
    top: 67%;
    left: 27%;
  }
  .visit-button-ob {
    position: absolute;
    top: 71%;
    left: 26%;
  }
}

@media (min-width: 1600px) and (max-width: 1700px) {
  .contact-button-ob {
    position: absolute;
    top: 61%;
    left: 24%;
  }
  .visit-button-ob {
    position: absolute;
    top: 66%;
    left: 22%;
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  .contact-button-ob {
    position: absolute;
    top: 52%;
    left: 20%;
  }
  .visit-button-ob {
    position: absolute;
    top: 53%;
    left: 16%;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .contact-button-ob {
    position: absolute;
    top: 46%;
    left: 17%;
  }
  .visit-button-ob {
    position: absolute;
    top: 48%;
    left: 13%;
  }
}

@media screen and (max-width: 480px) {
  .contact-button-ob {
    position: absolute;
    top: 64%;
    left: 25%;
  }
  .visit-button-ob {
    position: absolute;
    top: 68%;
    left: 23%;
  }
}

@media (min-width: 400px) and (max-width: 450px) {
  .contact-button-ob {
    position: absolute;
    top: 65%;
    left: 26%;
  }
  .visit-button-ob {
    position: absolute;
    top: 70%;
    left: 23%;
  }
}

@media (min-width: 380px) and (max-width: 400px) {
  .contact-button-ob {
    position: absolute;
    top: 60%;
    left: 22%;
  }
  .visit-button-ob {
    position: absolute;
    top: 64%;
    left: 21%;
  }
}

@media (min-width: 360px) and (max-width: 380px) {
  .contact-button-ob {
    position: absolute;
    top: 64%;
    left: 21%;
  }
  .visit-button-ob {
    position: absolute;
    top: 70%;
    left: 19%;
  }

  .visit-website-img {
    position: relative;
    height: auto;
    width: 100%;
  }
  .flip-container-ob {
    perspective: 1000px;
  }

  .flip-container-ob:hover .flipper-ob,
  .flip-container-ob:hover .flipper-ob {
    transform: rotateY(180deg);
  }

  .flipper-ob {
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
    width: 100%;
    height: 380px;
    max-width: 100%; /* Adjust this value as needed */
    /* height: auto; */
  }

  .front-ob,
  .back-ob {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .front-ob img,
  .back-ob img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Maintain aspect ratio */
  }

  .front-ob {
    z-index: 2;
  }

  .back-ob {
    transform: rotateY(180deg);
  }
}
