



.img-container {
  overflow: hidden;
}

.img-container img {
  width: 100%;
  transition: transform 4s;
}

.img-container img.zoom-in {
  transform: scale(1.5);
}





.img-container2 {
  overflow: hidden;
}

.img-container2 img {
  width: 100%;
  transition: transform 4s;
}

.img-container2 img.zoom-in {
  transform: scale(1.5);
}


@media (min-width:992px) and (max-width: 1024px) {
  .img-container{
    margin-top: 10%;
  }
  .img-container2{
    margin-top: 17%;
    
  }
  #parallax{
    height: 300px;
  }
  

}

