@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/***** Global Annimations *****/
.ease-out {
  animation-duration: 0.75s;
  animation-timing-function: ease-out;
}
.ease-in {
  animation-duration: 0.75s;
  animation-timing-function: ease-in;
}
.ease-out-left,
.ease-in-left {
  animation-name: slideIn;
}
.ease-out-right,
.ease-in-right {
  animation-name: slideOut;
}

body,
html {
  font-family: "Montserrat", sans-serif;
}

@keyframes slideIn {
  from {
    transform: translateX(-50px);
  }
  to {
    transform: translateX(0px);
  }
}
@keyframes slideOut {
  from {
    transform: translateX(50px);
  }
  to {
    transform: translateX(0px);
  }
}

.fade-in {
  animation: fadeIn 0.75s ease;
}
.fade-out {
  animation: fadeOut 0.75s ease;
}
.fade-in-slow {
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  from {
    scale: 0.25;
  }
  to {
    scale: 1;
  }
}
@keyframes fadeOut {
  from {
    scale: 1;
  }
  to {
    scale: 0.25;
  }
}

.fade-in-1 {
  animation: fadeIn 1s ease 0.25s;
}
.fade-in-2 {
  animation: fadeIn 1s ease 0.5s;
}
.fade-in-3 {
  animation: fadeIn 1s ease 0.75s;
}
.fade-in-4 {
  animation: fadeIn 1s ease 1s;
}

/***** End Global Annimations *****/

.cursor-pointer {
  cursor: pointer;
}

.body {
  overflow: hidden;
}
/***** For big screens *****/

@media (min-width: 1200px) and (max-width: 1300px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
}

@media (min-width: 1400px) and (max-width: 1500px) {
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 18px;
  }
  p {
    font-size: 16px;
  }
}

@media (min-width: 1600px) and (max-width: 1700px) {
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 20px;
  }
  h6 {
    font-size: 18px;
  }
  p {
    font-size: 16px;
  }
}

@media (min-width: 1900px) and (max-width: 2000px) {
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 22px;
  }
  h6 {
    font-size: 20px;
  }
  p {
    font-size: 18px;
  }
}

/* @media (min-width: 4480px) and (max-width: 4500px) {
  h1 {
    font-size: 6rem !important;
  }
  h2 {
    font-size: 44px;
  }
  h3 {
    font-size: 40px;
  }
  h4 {
    font-size: 36px;
  }
  h5 {
    font-size: 32px;
  }
  h6 {
    font-size: 30px;
  }
  p {
    font-size: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
} */
