.flip-container-archi {
  perspective: 1000px;
}

.flip-container-archi:hover .flipper-archi,
.flip-container-archi:hover .flipper-archi {
  transform: rotateY(180deg);
}

.flipper-archi {
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 370px;
}

.front-archi,
.back-archi {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.front-archi img,
.back-archi img {
  width: 100%;
  height: 350px;
  object-fit: cover; /* Maintain aspect ratio */
}

.front-archi {
  z-index: 2;
}

.back-archi {
  transform: rotateY(180deg);
}

.orange-architech-text {
  color: #f47e21;
}

@media (min-width: 4480px) and (max-width: 5000px) {
  .flip-container-archi {
    perspective: 1000px;
  }

  .flip-container-archi:hover .flipper-archi,
  .flip-container-archi:hover .flipper-archi {
    transform: rotateY(180deg);
  }

  .flipper-archi {
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
    width: 100%;
    height: 1300px;
  }

  .front-archi,
  .back-archi {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  .front-archi img,
  .back-archi img {
    width: 100%;
    height: 1200px;
    object-fit: cover; /* Maintain aspect ratio */
  }

  .front-archi {
    z-index: 2;
  }

  .back-archi {
    transform: rotateY(180deg);
  }
  .architects-heading {
    font-size: 6rem;
  }
  .architects-client-title {
    font-size: 4.5rem;
  }
  .orange-architech-text {
    font-size: 3.5rem;
  }
}

@media (min-width: 1900px) and (max-width: 2000px) {
  .flip-container-archi {
    perspective: 1000px;
  }

  .flip-container-archi:hover .flipper-archi,
  .flip-container-archi:hover .flipper-archi {
    transform: rotateY(180deg);
  }

  .flipper-archi {
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
    width: 100%;
    height: 420px;
  }

  .front-archi,
  .back-archi {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  .front-archi img,
  .back-archi img {
    width: 100%;
    height: 400px;
    object-fit: cover; /* Maintain aspect ratio */
  }

  .front-archi {
    z-index: 2;
  }

  .back-archi {
    transform: rotateY(180deg);
  }
}

@media (min-width: 1700px) and (max-width: 1900px) {
  .front-archi img,
  .back-archi img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .flipper-archi {
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
    width: 100%;
    height: 452px;
  }
}

@media (min-width: 1600px) and (max-width: 1700px) {
  .flip-container-archi {
    perspective: 1000px;
  }

  .flip-container-archi:hover .flipper-archi,
  .flip-container-archi:hover .flipper-archi {
    transform: rotateY(180deg);
  }

  .flipper-archi {
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
    width: 100%;
    height: 420px;
  }

  .front-archi,
  .back-archi {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  .front-archi img,
  .back-archi img {
    width: 100%;
    height: 400px;
    object-fit: cover; /* Maintain aspect ratio */
  }

  .front-archi {
    z-index: 2;
  }

  .back-archi {
    transform: rotateY(180deg);
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .flip-container-archi {
    perspective: 1000px;
  }

  .flip-container-archi:hover .flipper-archi,
  .flip-container-archi:hover .flipper-archi {
    transform: rotateY(180deg);
  }

  .flipper-archi {
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
    width: 100%;
    height: 320px;
  }

  .front-archi,
  .back-archi {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  .front-archi img,
  .back-archi img {
    width: 100%;
    height: 300px;
    object-fit: cover; /* Maintain aspect ratio */
  }

  .front-archi {
    z-index: 2;
  }

  .back-archi {
    transform: rotateY(180deg);
  }
}

@media screen and (max-width: 480px) {
  .flip-container-archi {
    perspective: 1000px;
  }

  .flip-container-archi:hover .flipper-archi,
  .flip-container-archi:hover .flipper-archi {
    transform: rotateY(180deg);
  }

  .flipper-archi {
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
    width: 100%;
    height: 420px;
  }

  .front-archi,
  .back-archi {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  .front-archi img,
  .back-archi img {
    width: 100%;
    height: 400px;
    object-fit: cover; /* Maintain aspect ratio */
  }

  .front-archi {
    z-index: 2;
  }

  .back-archi {
    transform: rotateY(180deg);
  }
}
