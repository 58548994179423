.header {
  background-color: transparent;
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 3;
}
.kaash-header {
  background-color: #00000013;
  margin-top: 0.2%;
  z-index: 3;
}
.orange-button-kaash2 {
  border-radius: 8px;
  background-color: #f57f20;
}
.header-relative {
  position: relative;
  height: 38.2px;
}
.header-top {
  position: fixed;
  z-index: 2;
}

.navbar-toggler:focus {
  outline: 0 !important;
  text-decoration: none;
  box-shadow: none !important;
}
.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;

  transition: var(--bs-navbar-toggler-transition);
  border: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
}

.header-top {
  background-color: #e7dccf;
}
.nav-links-text {
  color: rgb(255, 255, 255);
}
.nav-links-text:hover {
  color: #f57f20;
}
.orange-button-kaash {
  background-color: #f57f20;
}
.header-blogs {
  background-color: rgb(255, 255, 255);
}
.nav-links-text-black {
  color: black;
}
.nav-links-text-black:hover {
  color: #f57f20;
}

/* offcanvas */
/* .offcanvas.offcanvas-end.show {
  width: 70%;
  top: 4.65rem;
} */

@media (min-width: 4080px) and (max-width: 5000px) {
  .header a {
    font-size: 54px;
  }
  .header button {
    width: 459px;
    height: 100px;
    font-size: 53px;
  }
  .contact-button-home {
    font-size: 54px;
  }

  .orange-button-kaash2 {
    border-radius: 20px;
    background-color: #f57f20;
    font-size: 3rem;
  }

  .orange-button-kaash3 {
    border-radius: 20px;
    background-color: #f57f20;
    font-size: 3rem;
  }

  .kaash-logo-img {
    padding-top: 10%;
    width: 500px;
    height: 202px;
  }
  .nav-links-text-black {
    font-size: 3.3rem;
    margin-left: 60px;
  }
  .nav-links-text {
    font-size: 3.3rem;
    margin-left: 60px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .nav-links-text {
    color: black;
  }
  .nav-links-text:hover {
    color: #f57f20;
  }
}

@media (min-width: 480px) and (max-width: 992px) {
  .kaash-header {
    margin-top: 0%;
  }
  .header-relative {
    position: relative;
    height: 27.2px;
  }
  .nav-links-text {
    color: black;
  }
  .nav-links-text:hover {
    color: #f57f20;
  }
}

@media screen and (max-width: 480px) {
  .kaash-header {
    margin-top: 3.5%;
  }
  /* .offcanvas.offcanvas-end.show {
    width: 70%;
    top: -0.35rem;
  } */
  .nav-links-text {
    color: black;
  }
  .nav-links-text:hover {
    color: #f57f20;
  }
}

@media screen and (max-width: 280px) {
  .kaash-header {
    margin-top: 5%;
  }
  .contact-btn {
    padding: 1px;
  }
  .navbar-light img {
    height: 50%;
    width: 50%;
  }
}
